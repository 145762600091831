import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useStore } from '@nanostores/react';
import { currentOrder, currentOrderItem, removeOrderItem } from '../orderStore';
import OrderItemForm from './OrderItemForm';
import type { ZodModifier, ZodModifierGroup } from '@orderhub/shared';
import Button from './Button';

type Props = {
  modifierGroups: ZodModifierGroup[];
  modifiers: ZodModifier[];
};
const OrderItemModal = ({ modifierGroups, modifiers }: Props) => {
  const [itemModifiers, setItemModifier] = useState<ZodModifier[] | undefined>();
  const [itemModifierGroups, setItemModifierGroups] = useState<ZodModifierGroup[] | undefined>();
  const submitFormRef = useRef<HTMLButtonElement>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const orderItem = useStore(currentOrderItem);
  const order = useStore(currentOrder);

  useEffect(() => {
    if (orderItem) {
      const itemModifierGroups = modifierGroups.filter(
        (mg) =>
          orderItem.menuItemModifierGroupIds.includes(mg.id) || // one/extra mods
          orderItem.menuItemSizeModifierGroupId === mg.id // size mods
      );
      const itemModifiers = modifiers.filter((m) =>
        itemModifierGroups.map((mg) => mg.id).includes(m.modifierGroupId)
      );
      setItemModifierGroups(itemModifierGroups);
      setItemModifier(itemModifiers);
    }
  }, [orderItem]);

  const closeModal = () => {
    reset();
  };

  const reset = () => {
    setItemModifier(undefined);
    setItemModifierGroups(undefined);
    currentOrderItem.set(undefined);
  };

  const handleAddToOrder = async () => {
    setIsSubmitting(true);
    submitFormRef.current?.click();
    reset();
    setIsSubmitting(false);
  };

  const handleRemoveOrderItem = (orderItemId: string) => {
    setIsSubmitting(true);
    removeOrderItem(orderItemId);
    reset();
    setIsSubmitting(false);
  };

  if (!orderItem) return null;

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full transform rounded-lg bg-soft text-left shadow-xl transition-all sm:my-8 sm:max-w-xl">
                <div className="divide-y px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  {/* Menu item name & description */}
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="mb-3 text-lg font-medium leading-6 text-gray-900"
                      >
                        {orderItem.menuItemName}
                      </Dialog.Title>

                      {orderItem.menuItemDescription && (
                        <div className="mb-3">
                          <p className="text-sm text-gray-500">{orderItem.menuItemDescription}</p>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Form */}
                  {itemModifierGroups && itemModifiers && (
                    <OrderItemForm
                      modifiers={itemModifiers}
                      modifierGroups={itemModifierGroups}
                      orderItem={orderItem}
                      submitRef={submitFormRef}
                    />
                  )}
                </div>

                {/* Action buttons */}
                <div className="space-y-2 px-4 py-3 sm:flex sm:space-y-0 sm:px-6">
                  <div className={'sm:flex-grow'}>
                    {order.orderItems.map((oi) => oi.id).includes(orderItem.id) && orderItem.id && (
                      <Button
                        onClick={() => handleRemoveOrderItem(orderItem.id)}
                        disabled={isSubmitting}
                        variant="secondary"
                      >
                        Remove
                      </Button>
                    )}
                  </div>

                  <div
                    className={'space-y-2 sm:flex sm:flex-row-reverse sm:space-y-0 sm:space-x-4'}
                  >
                    <Button
                      onClick={handleAddToOrder}
                      disabled={isSubmitting}
                      className={'sm:ml-2'}
                    >
                      {order.orderItems.map((oi) => oi.id).includes(orderItem.id)
                        ? 'Update'
                        : 'Add to order'}
                    </Button>
                    <Button onClick={closeModal} variant="secondary">
                      Close
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default OrderItemModal;
