import type {
  OrderItemFormData,
  ZodModifier,
  ZodModifierGroup,
  ZodOrderItem,
} from '@orderhub/shared';
import { Controller, useForm } from 'react-hook-form';
import type { LegacyRef } from 'react';
import {
  applyOrderItemFormData,
  getOrderItemFormDefaultValues,
  orderItemFormSchema,
} from '@orderhub/shared';
import { zodResolver } from '@hookform/resolvers/zod';
import TextArea from './TextArea';
import { addOrUpdateOrderItem } from '../orderStore';
import ModifierSelectList from './ModifierSelectList';
import ModifierMultiSelectList from './ModifierMultiSelectList';
import SelectList from './SelectList';

type Props = {
  modifierGroups: ZodModifierGroup[];
  modifiers: ZodModifier[];
  orderItem: ZodOrderItem;
  submitRef: LegacyRef<HTMLButtonElement>;
};

const OrderItemForm = ({ modifierGroups, modifiers, orderItem, submitRef }: Props) => {
  const oneModifierGroups = modifierGroups.filter((mg) => mg.qtyMax === 1);
  const manyModifierGroups = modifierGroups.filter((mg) => mg.qtyMax > 1);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<OrderItemFormData>({
    resolver: zodResolver(orderItemFormSchema), // Useful to check TypeScript regressions
    defaultValues: getOrderItemFormDefaultValues(orderItem, modifierGroups, modifiers),
  });

  const onValidSubmit = async (formData: OrderItemFormData) => {
    try {
      orderItem = applyOrderItemFormData(orderItem, modifierGroups, formData);
      addOrUpdateOrderItem(orderItem);
    } catch (e) {
      console.error(e);
      throw new Error(JSON.stringify(e));
    }
  };

  return (
    <form
      className="grid items-start gap-6 pt-3 sm:grid-cols-2"
      onSubmit={handleSubmit(onValidSubmit)}
    >
      {/* column 1: One mods */}
      <div>
        {/* Price only (if no item has no mods) */}
        {oneModifierGroups && oneModifierGroups.length === 0 && !!orderItem.menuItemPrice && (
          <div className={'mb-3'}>${orderItem.menuItemPrice.toFixed(2)}</div>
        )}

        {/* Qty */}
        <div className="mb-3 w-20">
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <SelectList
                options={Array(99)
                  .fill(1)
                  .map((n, i) => (n + i).toString())}
                selectedOption={value.toString()}
                onChange={(value) => {
                  onChange(+value); // convert from string to number
                }}
                label={'Qty'}
              />
            )}
            name={`qty`}
          />
        </div>

        {oneModifierGroups.map((mg, index) => {
          return (
            <div key={mg.id} className="mb-3">
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ModifierSelectList
                    modifierGroup={mg}
                    modifiers={modifiers.filter((m) => m.modifierGroupId === mg.id)}
                    onChange={onChange}
                    selectedModifier={value}
                  />
                )}
                name={`selectedOneModifiers.${index}`}
              />
            </div>
          );
        })}

        {/* Item notes */}
        <div className="mt-3 block text-sm font-semibold text-gray-700">Notes</div>
        <Controller
          control={control}
          render={({ field: { onBlur, onChange, value, name } }) => (
            <TextArea
              placeholder="Add any notes here..."
              className={'mt-1'}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              name={name}
              id={`txt-${name}`}
              rows="2"
            />
          )}
          name={`notes`}
        />
      </div>

      {/* column 2: Extra mods */}
      {manyModifierGroups && manyModifierGroups.length > 0 && (
        <div>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <ModifierMultiSelectList
                selectedModifiers={value}
                onChange={onChange}
                modifierGroups={manyModifierGroups}
                modifiers={modifiers.filter((m) =>
                  manyModifierGroups.map((mg) => mg.id).includes(m.modifierGroupId)
                )}
              />
            )}
            name={'selectedManyModifiers'}
          />
        </div>
      )}

      {/* Submit button (hidden): Called from parent */}
      <button ref={submitRef} type="submit" style={{ display: 'none' }} />
      {/*{JSON.stringify(errors)}*/}
    </form>
  );
};

export default OrderItemForm;
